import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { H2, StyledHeading, P } from '../components/page-elements'
import { StaticImage } from 'gatsby-plugin-image'

const PageContainer = styled.div`
  padding-top: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0;
  min-height: 75vh;
  margin-bottom: 100px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @media(min-width: 768px) {
    margin-bottom: 200px;
  }
`
const Header = styled.header`
  position: relative;
  padding-top: 150px;

  margin-bottom: 100px;
  text-align: center;
`
const FlexSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-gap: 2rem;
  align-items: center;
  margin-bottom: 10vh;

  @media(min-width: 768px) {
    flex-direction: row;
    gap: 4rem;
    grid-gap: 4rem;
    position: relative;
    z-index: 2;
    &:nth-of-type(odd){
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 60%;
        left: -35px;
        right: 30%;
        bottom: -25px;
        background: hsla(${({theme}) => theme.teal.hsl}, .25);
        z-index: -1;
      }
    }
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
    /* target image and text */
    >:first-child {
      flex: 0 0 35%;      
    }
    >:nth-child(2) {
      flex: 1 1 65%;
    }
  }
`
const Subheading = styled(H2)`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: hsla(${({theme}) => theme.text}, 1);
`
const Paragraph = styled(P)`
  line-height: 1.5;
  margin-bottom: 1rem;
  max-width: 100%;
  color: hsla(${({theme}) => theme.text}, 1);
`


const About = ({ location }) => {
  return (
    <Layout
      title="About Us"
      pathname={location.pathname}
    >
      <PageContainer>
        <Header>
          <StyledHeading>Our Story</StyledHeading>
        </Header>
    
        <FlexSection>
          <StaticImage
            src="../images/sprout-close-crop.jpg"
            alt="Close up of a starter plant"
            aspectRatio="1"
            placeholder="blurred"
          />
          <div>
            <Subheading>The Beginning</Subheading>
            <Paragraph>
              In May of 2019, a group of close friends and family members from Ionia came together with an idea. 
              In light of new laws, we wanted to grow medical cannabis in the legal market.
              With deep roots in Michigan's caregiver and underground Medical Marijuana market, the passing of the MMFLA law presented us the opportunity to get into the licensed market. 
            </Paragraph>
          </div>
        </FlexSection>

        <FlexSection>
          <StaticImage
            src="../images/sprouts-in-rows-crop.jpg"
            alt="Plant sprouts growing in rows"
            aspectRatio="1"
            placeholder="blurred"
          />
          <div>
            <Subheading>Easton Craft Cultivation</Subheading>
            <Paragraph>
              After working with Easton Township and the community for over a year, we were able to get a Class A Medical Marijuana Grow license. 
              This became known as Easton Craft Cultivation. After successfully operating Easton Craft Cultivation for a year, 
              we have been fortunate enough to expand to a Class C Recreational Grow License.
            </Paragraph>
          </div>          
        </FlexSection>

        <FlexSection>
          <StaticImage
            src="../images/plants-in-rows-crop.jpg"
            alt="Medium sized plants growing in rows"
            aspectRatio="1"
            placeholder="blurred"
          />
          <div>
            <Subheading>Retail Storefront</Subheading>
            <Paragraph>
              With expansions to the grow facility on the way, we are excited to present the Easton Craft adult-use retail store.
              We are excited to offer numerous strains cultivated by us, right here in Ionia, as well as some of the best flower, 
              concentrates, edibles, topicals and oils from some of our greatest friends from around the state. We will also offer a wide variety of non THC accessories and CBD products. 
            </Paragraph>
          </div>
        </FlexSection>
      </PageContainer>
    </Layout>
  )
}

export default About
